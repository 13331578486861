@use '@matillion/component-library';
@use '@matillion/component-library/dist/assets/styles/_colours.scss';

* {
  margin: 0;
  padding: 0;
}

body,
input {
  color: colours.$black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}
